export const atlanta = [{
  image: require('./IMG_1215.jpg'),
  note: 'One of my favorite pictures of us 😍',
}, {
  image: require('./IMG_1557.PNG'),
  note: 'Virtual runs were fun hehe',
}, {
  image: require('./IMG_4246.JPG'),
  note: 'Sacrificed my hair for group fun?',
}, {
  image: require('./IMG_2309.JPG'),
  note: 'Bahhhhhh',
}, {
  image: require('./IMG_4253.jpg'),
  note: `I don't know which one's sweeter 😛`,
}, {
  image: require('./IMG_6657.jpg'),
  note: 'Holding hands secretly in the back seat of the car 🫀',
}];
