export const moolah = [{
  image: require('./IMG_4094.JPG'),
  note: 'NGL a masterpiece from Maya.',
}, {
  image: require('./IMG_1993.JPG'),
  note: '❤️',
}, {
  image: require('./IMG_2003.JPG'),
  note: 'The Moolah film crew 👌',
}, {
  image: require('./IMG_2017.JPG'),
  note: 'Submitted one minute before the deadline :)',
}, {
  image: require('./IMG_4184.JPG'),
  note: 'Nobu celebrated the submission with us 💦',
}];
