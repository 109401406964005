export const ncr = [{
  image: require('./IMG_4281.JPG'),
  note: 'Thanks Lulu, Womas, and Aleks for the smooth onboarding experience.',
}, {
  image: require('./IMG_2353.jpg'),
  note: 'Googler wearing Gatherly at NCR 👀',
}, {
  image: require('./IMG_2253.JPG'),
  note: 'Featured on their corporate TikTok ;)',
}, {
  image: require('./IMG_2112.JPG'),
  note: '🥰',
}];
