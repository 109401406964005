export const gatherly = [{
  image: require('./Screen Shot 2021-04-01 at 5.49.51 PM.png'),
  note: 'Loved our spontaneous drawing collab session!'
}, {
  image: require('./IMG_3263.JPG'),
  note: 'Quitting Gatherly around the same time made us friends heh',
}, {
  image: require('./gatherly-photobooth-0360 (1).png'),
  note: 'I have so much love for Gatherly, and Lulu on Gatherly? I die.',
}, {
  image: require('./9_1 Gatherly.png'),
  note: 'Spending hours sharing each of our travel stories 😍 More to make together!',
}, {
  image: require('./IMG_3496.jpg'),
  note: 'Gatherly meetup (feat. Isa) out of nowhere in New York!',
}];
