export const year0 = [{
  image: require('./jan.jpg'),
  note: `January, Lulu's first birthday together with our favorite frensss 🥰`,
}, {
  image: require('./feb.jpg'),
  note: 'February, with our spontaneous drinking buds in Miami! Also first and last time (so far) witnessing Lulu throwing up 😜',
}, {
  image: require('./mar.jpg'),
  note: `March, last minute hotel searching didn't go as planned (didn't really plan anything lol) but we made it 😮‍💨`,
}, {
  image: require('./april.jpg'),
  note: `April, the 3 loveliest girls in one photo 😇`,
}, {
  image: require('./may.jpg'),
  note: 'May, the most wholesome hot pot sesh ever in my life!',
}, {
  image: require('./jun.jpg'),
  note: `June, I was soOoOo proud and glad that we work for the same company again 👩🏻‍💻❤️🧑🏻‍💻`,
}, {
  image: require('./jul.jpg'),
  note: 'July, so much fun in Tahoe 🌅 and Yosemite 🏞️ as a full-time ඞ among our intern friends.',
}, {
  image: require('./aug.jpg'),
  note: 'August, randomly ran into a cute LV exhibition in LA! We complete the cuteness tho 😋',
}, {
  image: require('./sep.jpg'),
  note: 'September, sad bye bye to Lulu leaving to Glasgow for months 😭',
}, {
  image: require('./oct.jpg'),
  note: `October, just kidding, it took little over a month (but felt like a lifetime!) for Oslo to get us back together 🥰 Also had an amazing dinner at J2 Korean 🤤`,
}, {
  image: require('./nov.jpg'),
  note: `November, roadtripping in Iceland with Lulu and friends! I would've frozen without you and your hat 😜`,
}, {
  image: require('./dec.jpg'),
  note: `December, solo travel modes in different parts of the world for a while 🥲 CAN'T WAIT TO SEE YOU IN A FEW MONTHS!!!`,
}, {
  image: require('./hotelscom.png'),
  note: 'Happy 23rd birthday, Lulu ❤️ Love you and miss you sooo machiii 💗 Not much, but click me to subsidize your travel a bit! Fun and safe travels 💞',
  link: 'https://drive.google.com/file/d/1YWze9xUmXCyhxZ8fIN6VXSSQOHO7bxeY/view',
}];