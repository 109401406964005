export const nyc = [{
  image: require('./IMG_3460.JPG'),
  note: 'Coffee commercial vibe ☕️',
}, {
  image: require('./IMG_3477.jpg'),
  note: 'The best and worst drunk ferry ride!',
}, {
  image: require('./IMG_5009.jpg'),
  note: `I shouldn't have stuck my ass out aiyaa`,
}];
